@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Black.67d3d004.woff2) format('woff2'),
        url(/static/media/Montserrat-Black.7cff12a5.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Bold.ecfa764e.woff2) format('woff2'),
        url(/static/media/Montserrat-Bold.87be09dc.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraBold.8727ddb9.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraBold.c1a66070.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraLight.34e7a400.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraLight.88f22562.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-BlackItalic.201622df.woff2) format('woff2'),
        url(/static/media/Montserrat-BlackItalic.0f7f4751.woff) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraBoldItalic.4007d242.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraBoldItalic.77a07952.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-BoldItalic.ff3f635d.woff2) format('woff2'),
        url(/static/media/Montserrat-BoldItalic.454ae2c7.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-SemiBoldItalic.8541765b.woff2) format('woff2'),
        url(/static/media/Montserrat-SemiBoldItalic.1dd40918.woff) format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-MediumItalic.19409701.woff2) format('woff2'),
        url(/static/media/Montserrat-MediumItalic.1b9eebc2.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Light.73d46b19.woff2) format('woff2'),
        url(/static/media/Montserrat-Light.c154e051.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-LightItalic.742d0029.woff2) format('woff2'),
        url(/static/media/Montserrat-LightItalic.32c03573.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ExtraLightItalic.69c47c28.woff2) format('woff2'),
        url(/static/media/Montserrat-ExtraLightItalic.7f6071dc.woff) format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Medium.80941f1a.woff2) format('woff2'),
        url(/static/media/Montserrat-Medium.5d15323c.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-SemiBold.a7345293.woff2) format('woff2'),
        url(/static/media/Montserrat-SemiBold.e3ec793c.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Italic.ca6d1e68.woff2) format('woff2'),
        url(/static/media/Montserrat-Italic.69a48d2f.woff) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Regular.db685431.woff2) format('woff2'),
        url(/static/media/Montserrat-Regular.ad6d5536.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Thin.5fe5293c.woff2) format('woff2'),
        url(/static/media/Montserrat-Thin.87bf8f92.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-ThinItalic.8a47d539.woff2) format('woff2'),
        url(/static/media/Montserrat-ThinItalic.e6bf30bf.woff) format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Bold.84adee34.woff2) format('woff2'),
        url(/static/media/Roboto-Bold.71cc7f4d.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Italic.0c62151b.woff2) format('woff2'),
        url(/static/media/Roboto-Italic.55cca991.woff) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-BlackItalic.78a835e0.woff2) format('woff2'),
        url(/static/media/Roboto-BlackItalic.449f9a04.woff) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-BoldItalic.0b369e1e.woff2) format('woff2'),
        url(/static/media/Roboto-BoldItalic.86c4536d.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Black.a3c964df.woff2) format('woff2'),
        url(/static/media/Roboto-Black.f4e373e0.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Light.83332147.woff2) format('woff2'),
        url(/static/media/Roboto-Light.2c231254.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-ThinItalic.a4b35a9c.woff2) format('woff2'),
        url(/static/media/Roboto-ThinItalic.6d9da0ba.woff) format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Regular.6ebf208d.woff2) format('woff2'),
        url(/static/media/Roboto-Regular.60ef8b89.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Medium.b4bc083c.woff2) format('woff2'),
        url(/static/media/Roboto-Medium.cb4b0e51.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Thin.f45dc927.woff2) format('woff2'),
        url(/static/media/Roboto-Thin.ae3a803c.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-MediumItalic.2ee1e62f.woff2) format('woff2'),
        url(/static/media/Roboto-MediumItalic.aebc8065.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-LightItalic.74fda2d0.woff2) format('woff2'),
        url(/static/media/Roboto-LightItalic.b1901583.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraBold.2fcf9e5e.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraBold.87ebf43e.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraBold.2fcf9e5e.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraBold.87ebf43e.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Bold.16f817e3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Bold.9e36cdea.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Bold.16f817e3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Bold.9e36cdea.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Bold.16f817e3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Bold.9e36cdea.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Medium.53ba53b3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Medium.b8e22bfe.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraLight.dc79939f.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraLight.5d89ed74.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraBold.2fcf9e5e.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraBold.87ebf43e.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Light.32134ac2.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Light.a51a8ba5.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Regular.04682480.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Regular.2a42c9a4.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Light.32134ac2.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Light.a51a8ba5.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Light.32134ac2.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Light.a51a8ba5.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Regular.04682480.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Regular.2a42c9a4.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraLight.dc79939f.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraLight.5d89ed74.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Regular.04682480.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Regular.2a42c9a4.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Medium.53ba53b3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Medium.b8e22bfe.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-ExtraLight.dc79939f.woff2) format('woff2'),
        url(/static/media/EudoxusSans-ExtraLight.5d89ed74.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(/static/media/EudoxusSans-Medium.53ba53b3.woff2) format('woff2'),
        url(/static/media/EudoxusSans-Medium.b8e22bfe.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Bold.f78076ce.woff2) format('woff2'),
        url(/static/media/Inter-Bold.2d3fbb36.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Medium.6c6673e5.woff2) format('woff2'),
        url(/static/media/Inter-Medium.b67e1204.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Light.ceeb8712.woff2) format('woff2'),
        url(/static/media/Inter-Light.a58325fc.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-ExtraBold.ea6fc194.woff2) format('woff2'),
        url(/static/media/Inter-ExtraBold.c505e603.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-ExtraLight.5bbb8a4d.woff2) format('woff2'),
        url(/static/media/Inter-ExtraLight.a2fb66b1.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Black.f3e3958b.woff2) format('woff2'),
        url(/static/media/Inter-Black.4e1e0ecf.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Regular.a0ff0396.woff2) format('woff2'),
        url(/static/media/Inter-Regular.f901385f.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-SemiBold.0eec2995.woff2) format('woff2'),
        url(/static/media/Inter-SemiBold.5d3fb944.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/Inter-Thin.244b06ac.woff2) format('woff2'),
        url(/static/media/Inter-Thin.6601311f.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


.tournament-slider .slick-slider .slick-slide {
    padding-right: 0px !important;
    /* Other styles */
}

.slick-slider .slick-slide {
    /* padding-right: 15px !important; */
}

html, body {
    overflow-x: hidden !important;
}

body::-webkit-scrollbar,
body *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
    background-color: rgba(61, 61, 61, 0.5);
    border-radius: 4px;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}

body::-webkit-scrollbar-thumb:hover,
body *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.6);
    width: 12px !important;
}

body::-webkit-scrollbar-track,
body *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

body::-webkit-scrollbar-corner,
body *::-webkit-scrollbar-corner {
    background-color: transparent;
}

.css-14u9vth-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px !important;
    font-size: 15px !important;
    height: 40px !important;
}

.css-wtw5vo-MuiInputBase-input-MuiOutlinedInput-input {
    height: 13px !important;
}

.css-17jfbmq-MuiFormLabel-root-MuiInputLabel-root {
    top: -5px !important;
}

.css-gthdbs-MuiTableCell-root {
    padding: 8px !important;
}

.css-4hqp1a-MuiContainer-root {
    padding-left: 2px !important;
    padding-right: 1px !important;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    height: 40px !important;
    font: inherit !important;
    height: 1.4375em !important;
    letter-spacing: inherit !important;
    display: flex !important;
    color: #6b778c !important;
}

.settings-phone > .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    height: 40px !important;
    font: inherit !important;
    height: 1.4375em !important;
    letter-spacing: inherit !important;
    display: flex !important;
    color: #6b778c !important;
    background: #fbfbfb !important;
}

/* CSS variables. */
:root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: currentColor;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInputInput:focus-visible {
    flex: 1 1;
    min-width: 0;
    border: none;
    outline: none;
}

.PhoneInputCountryIcon {
    width: calc(1em * 1.5);
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: 1em;
    height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
    width: 1em;
    width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);

    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);

    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
    display: block;

    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: 0.8;
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: 0.65;
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    width: var(--PhoneInputCountrySelectArrow-width);
    height: 0.3em;
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: 0.35em;
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: currentColor;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: 1px;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: 1px;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
            transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: 0.45;
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb,
    inset 0 0 0 1px #03b2cb;
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.css-54j8as-MuiListItem-root {
    overflow: hidden !important;
    white-space: nowrap !important;
}

.MuiListItem-root .active .MuiButton-startIcon svg path {
    fill: #ffff;
}

.MuiListItem-root .MuiButton-startIcon svg path {
    fill: #86909f;
}

.MuiListItem-root .active .MuiBox-root {
    color: #ffff;
}

.MuiListItem-root:hover .MuiButton-startIcon svg path {
    fill: white;
    transition: fill 0.3s ease;
}

.slick-slide > div {
    margin: 0 8px;
}

.slick-list {
    margin: 0 -8px;
}

.my-slider > * .slick-slide > div {
    margin: 0 0px;
}

.banner-slider {
    margin-bottom: -7px;
    padding-right: 17px;
}

.banner-slider .slick-slide > div {
    margin: auto;
}

.banner-slider .slick-list {
    margin: auto;
}

.banner-slider-img {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

:root {
    --navy-blue-color: '#0e0e0e';
    --secondary-grey-color: '#757575';
}

.calendar-custom-wrapper {
    position: absolute;
    /* top: 48px !important; */
    z-index: 9999999 !important;
    /* height: 480px !important; */
    background: #fff;
}

.dashboard-date-picker .react-calendar {
    max-width: 320px;
    background: '#0e0e0e';
    background: var(--navy-blue-color);
    box-shadow: 3px 4px 69px rgb(0 0 0 / 50%);
    border: none;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    padding: 28px 22px;
    width: auto;
}

.dashboard-date-picker .react-calendar {
    position: relative;
}

.dashboard-date-picker .react-calendar__navigation button {
    color: #ff5733;
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    background: none;
    border: 0;
}

.dashboard-date-picker .react-calendar__navigation__label {
    color: #0a2540 !important;
}

.dashboard-date-picker .react-calendar__navigation button:disabled {
    background: '#0e0e0e';
    background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:first-child,
.dashboard-date-picker .react-calendar__navigation button:last-child {
    display: none;
}

.dashboard-date-picker .search-calendar-btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.dashboard-date-picker .search-calendar-btn::before {
    background-color: '#0e0e0e';
    background-color: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:last-child::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 4px;
    background-color: '#0e0e0e';
    background-color: var(--navy-blue-color);
    z-index: -1;
}

.dashboard-date-picker .react-calendar__navigation button:last-child::after {
    content: '';
    font-size: 16px;
    background: linear-gradient(to left, #00ffa3, #000) !important;
    -webkit-background-clip: text;
    color: transparent;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(3) {
    flex-grow: inherit !important;
    pointer-events: none !important;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2),
.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
    padding: 0px;
    width: 24px;
    border-radius: 50%;
    min-width: unset;
    position: absolute;
    top: 40px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2) {
    right: 48px;
    padding-right: 2px;
    top: 30px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
    right: 22px;
    padding-left: 2px;
    top: 30px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
    background-color: #ff5733 !important;
    color: white;
}

.dashboard-date-picker .react-calendar__month-view__weekdays {
    color: '#757575';
    color: var(--secondary-grey-color);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
    margin-top: 32px !important;
}

.dashboard-date-picker .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.dashboard-date-picker .react-calendar__tile {
    border: none;
    background: none;
    color: '#757575';
    color: var(--secondary-grey-color);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px;
    border-radius: 50%;
    margin-top: 8px;
    cursor: pointer;
    /* margin-left: 4px;
      margin-right: 4px; */
}

.dashboard-date-picker .react-calendar__tile:disabled {
    background: '#0e0e0e';
    background: var(--navy-blue-color);
    border-radius: 50%;
}

.dashboard-date-picker .react-calendar__tile--rangeStart,
.dashboard-date-picker .react-calendar__tile--rangeEnd,
.dashboard-date-picker .react-calendar__tile--hasActive,
.dashboard-date-picker .react-calendar__tile:enabled:hover,
.dashboard-date-picker .react-calendar__tile:enabled:focus {
    /* background: var(--secondary-neon-color) !important; */
    background-color: #ff5733 !important;
    border-radius: 50%;
    color: white !important;
}

.dashboard-date-picker .react-calendar__tile--active,
.dashboard-date-picker .react-calendar--selectRange .react-calendar__tile--hover {
    background: linear-gradient(100deg, #ff5733 0.25%, #fd4c26 46.18%, #fa8d76 97.15%);
    color: white !important;
    border-radius: 50% !important;
}

.dashboard-date-picker .react-calendar__tile--now {
    /* background: transparent; */
    border-radius: 50%;
}

.analytics-calendar-wrapper {
    position: relative;
}

.analytics-calendar-wrapper .calendar-custom-wrapper {
    /* top: 40px !important; */
}

.overview-banner,
.overview-banner .slick-list,
.overview-banner .slick-track,
.overview-banner .slick-slide > div:first-child {
    height: 100%;
}

.test .slick-track {
    margin-left: 0;
    margin-right: 0;
}

iframe [name='__uspapiLocator'] {
    display: none !important;
}

@media screen and (max-width: 599px) {
    .banner-slider {
        padding-right: 0px;
    }

    .banner-slider-img {
        border-radius: 6px;
        height: 150px;
    }
}

/* .jknkFS::after {
  border-color: #efefef !important;
  top: calc(50% - 1.5px) !important;
} */
.MuiPickersDay-today {
    border: none !important;
}

.qualWinners:nth-child(even)::before {
    border-top: none !important;
}

.qualWinners:nth-child(odd):not(:last-child)::after {
    border-right: none !important;
    border-top: none !important;
}

.qualWinners:nth-child(even)::after {
    border-bottom: none !important;
    border-right: none !important;
}

.qualWinnerBox::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 1px;
    background-color: #eee;
}

.gm-style-mtc-bbw .gm-style-mtc:first-of-type > button {
    height: 25px !important;
    font-size: 12px !important;
}

.gm-style-mtc-bbw .gm-style-mtc:last-of-type > button {
    font-size: 12px !important;
    height: 25px !important;
}

.jknkFS::after {
    border-color: #efefef !important;
    /* top: calc(50% - 12.5px) !important; */
}

/* .dWYBbN::after{
  border-color: #efefef !important;

} */
.dWYBbN::before {
    border-color: #efefef !important;
    border-top: 1px solid #efefef !important;
}

.gCXEnU::after {
    border-color: #efefef !important;
}

.gCXEnU::before {
    border-color: #efefef !important;
}

.jknkFS::before {
    border-color: #efefef !important;
    /* top: calc(50% - 1.5px) !important; */
}

.MuiPickersDay-today {
    border: none !important;
}

.MuiDialogActions-root button {
    background: transparent !important;
}

.MuiDialogActions-root button:hover {
    color: #ff5733;
}

.date-of-birth .MuiInputBase-formControl {
    height: 50px !important;
}

.MuiOutlinedInput-root {
    border: 1px solid #efeff0;
}

.MuiOutlinedInput-root fieldset {
    border-width: 0px;
}

.MuiOutlinedInput-root:hover fieldset {
    border-width: 0px;
    -webkit-transform: none;
            transform: none; /* Added the transform property */
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-width: 0px;
}

/* For .MuiOutlinedInput-input */
.MuiOutlinedInput-input {
    /* padding: 9px 14px; */
    padding-right: 0px !important; /* Added !important to override any existing styles */
}

*,
html {
    scroll-behavior: smooth;
}

.my-scroller::-webkit-scrollbar {
    display: none !important;
}

/* .my-scroller:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, transparent, white);
  pointer-events: none;
} */
:root {
    --primary-color: #ff5733; /* Fallback color */
}

.svg-current-color,
.svg-current-color path {
    fill: currentColor;
}

.svg-current-color_generic,
.svg-current-color_generic path {
    fill: #ff5733;
    fill: var(--primary-color);
}

.svg-current-signup,
.svg-current-signup path {
    stroke: #ff5733;
    stroke: var(--primary-color);
}

.svg-current-arrows,
.svg-current-arrows rect {
    fill: #ff5733;
    fill: var(--primary-color);
}

.test-slider img {
    max-width: 55%;
    margin: auto;
}

.custom .MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.custom .MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* [dir='ltr'] .notFirst.sc-imWYAI:nth-child(odd):not(:last-child)::before {
  content: '';
  position: absolute;
  height: 50%;
  width: 1.5em;
  left: 0;
  border-top: 1px solid #efeff0 !important;
  border-left: 1px solid #efeff0 !important;
  top: calc(50% - 0.5px);
}

[dir='ltr'] .notFirst.sc-imWYAI:nth-child(even)::before {
  border-left: 1px solid #efeff0 !important;
  border-bottom: 1px solid #efeff0 !important;
  border-top: 0;
  top: -0.5px;
  left: 0px;
  content: '';
  position: absolute;
  height: 50%;
  width: 1.5em;
} */
/* .odd > div > div > div:nth-child(2) > div > div:nth-child(2) {
  width: 94%;
  margin: 0 10px;
}
.even > div > div > div > div > div:nth-child(2) {
  width: 94%;
  margin: 0 10px;
} */
@media (max-width: 768px) {
    /* Adjust the max-width value based on your target screen size */
    .odd > div > div > div:nth-child(2) > div > div:nth-child(2) {
        width: 94%;
        margin: 0 10px;
    }

    .even > div > div > div > div > div:nth-child(2) {
        width: 94%;
        margin: 0 10px;
    }
}

.highlighted > div > div:nth-child(1) > div {
    background-color: #fae5e5;
}

/* .gCXEnU {
  padding: 0em 1.3em;
} */
.lazy-load-image-background.opacity {
    opacity: 1;
}

iframe {
    display: none;
}

.MuiTabs-scrollButtons {
    display: inherit !important;
    padding: 0px 0px 10px 0px;
    width: -webkit-max-content !important;
    width: max-content !important;
}


@media screen and (max-width: 600px) {
    #CookiebotWidget:not(.CookiebotWidget-inactive) {
        bottom: 65px !important;
        left: 10px;
    }
}

#CookiebotWidget .CookiebotWidget-logo svg path {
    fill: #ffffff !important;
}
