@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.tournament-slider .slick-slider .slick-slide {
    padding-right: 0px !important;
    /* Other styles */
}

.slick-slider .slick-slide {
    /* padding-right: 15px !important; */
}

html, body {
    overflow-x: hidden !important;
}

body::-webkit-scrollbar,
body *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
    background-color: rgba(61, 61, 61, 0.5);
    border-radius: 4px;
    transition: width 0.3s;
}

body::-webkit-scrollbar-thumb:hover,
body *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.6);
    width: 12px !important;
}

body::-webkit-scrollbar-track,
body *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

body::-webkit-scrollbar-corner,
body *::-webkit-scrollbar-corner {
    background-color: transparent;
}

.css-14u9vth-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px !important;
    font-size: 15px !important;
    height: 40px !important;
}

.css-wtw5vo-MuiInputBase-input-MuiOutlinedInput-input {
    height: 13px !important;
}

.css-17jfbmq-MuiFormLabel-root-MuiInputLabel-root {
    top: -5px !important;
}

.css-gthdbs-MuiTableCell-root {
    padding: 8px !important;
}

.css-4hqp1a-MuiContainer-root {
    padding-left: 2px !important;
    padding-right: 1px !important;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    height: 40px !important;
    font: inherit !important;
    height: 1.4375em !important;
    letter-spacing: inherit !important;
    display: flex !important;
    color: #6b778c !important;
}

.settings-phone > .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    height: 40px !important;
    font: inherit !important;
    height: 1.4375em !important;
    letter-spacing: inherit !important;
    display: flex !important;
    color: #6b778c !important;
    background: #fbfbfb !important;
}

/* CSS variables. */
:root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: currentColor;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInputInput:focus-visible {
    flex: 1;
    min-width: 0;
    border: none;
    outline: none;
}

.PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);

    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
    display: block;

    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.css-54j8as-MuiListItem-root {
    overflow: hidden !important;
    white-space: nowrap !important;
}

.MuiListItem-root .active .MuiButton-startIcon svg path {
    fill: #ffff;
}

.MuiListItem-root .MuiButton-startIcon svg path {
    fill: #86909f;
}

.MuiListItem-root .active .MuiBox-root {
    color: #ffff;
}

.MuiListItem-root:hover .MuiButton-startIcon svg path {
    fill: white;
    transition: fill 0.3s ease;
}

.slick-slide > div {
    margin: 0 8px;
}

.slick-list {
    margin: 0 -8px;
}

.my-slider > * .slick-slide > div {
    margin: 0 0px;
}

.banner-slider {
    margin-bottom: -7px;
    padding-right: 17px;
}

.banner-slider .slick-slide > div {
    margin: auto;
}

.banner-slider .slick-list {
    margin: auto;
}

.banner-slider-img {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

:root {
    --navy-blue-color: '#0e0e0e';
    --secondary-grey-color: '#757575';
}

.calendar-custom-wrapper {
    position: absolute;
    /* top: 48px !important; */
    z-index: 9999999 !important;
    /* height: 480px !important; */
    background: #fff;
}

.dashboard-date-picker .react-calendar {
    max-width: 320px;
    background: var(--navy-blue-color);
    box-shadow: 3px 4px 69px rgb(0 0 0 / 50%);
    border: none;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    padding: 28px 22px;
    width: auto;
}

.dashboard-date-picker .react-calendar {
    position: relative;
}

.dashboard-date-picker .react-calendar__navigation button {
    color: #ff5733;
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    background: none;
    border: 0;
}

.dashboard-date-picker .react-calendar__navigation__label {
    color: #0a2540 !important;
}

.dashboard-date-picker .react-calendar__navigation button:disabled {
    background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:first-child,
.dashboard-date-picker .react-calendar__navigation button:last-child {
    display: none;
}

.dashboard-date-picker .search-calendar-btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 24px;
    bottom: 24px;
}

.dashboard-date-picker .search-calendar-btn::before {
    background-color: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:last-child::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 4px;
    background-color: var(--navy-blue-color);
    z-index: -1;
}

.dashboard-date-picker .react-calendar__navigation button:last-child::after {
    content: '';
    font-size: 16px;
    background: linear-gradient(to left, #00ffa3, #000) !important;
    -webkit-background-clip: text;
    color: transparent;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(3) {
    flex-grow: inherit !important;
    pointer-events: none !important;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2),
.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
    padding: 0px;
    width: 24px;
    border-radius: 50%;
    min-width: unset;
    position: absolute;
    top: 40px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2) {
    right: 48px;
    padding-right: 2px;
    top: 30px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
    right: 22px;
    padding-left: 2px;
    top: 30px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
    background-color: #ff5733 !important;
    color: white;
}

.dashboard-date-picker .react-calendar__month-view__weekdays {
    color: var(--secondary-grey-color);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
    margin-top: 32px !important;
}

.dashboard-date-picker .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.dashboard-date-picker .react-calendar__tile {
    border: none;
    background: none;
    color: var(--secondary-grey-color);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px;
    border-radius: 50%;
    margin-top: 8px;
    cursor: pointer;
    /* margin-left: 4px;
      margin-right: 4px; */
}

.dashboard-date-picker .react-calendar__tile:disabled {
    background: var(--navy-blue-color);
    border-radius: 50%;
}

.dashboard-date-picker .react-calendar__tile--rangeStart,
.dashboard-date-picker .react-calendar__tile--rangeEnd,
.dashboard-date-picker .react-calendar__tile--hasActive,
.dashboard-date-picker .react-calendar__tile:enabled:hover,
.dashboard-date-picker .react-calendar__tile:enabled:focus {
    /* background: var(--secondary-neon-color) !important; */
    background-color: #ff5733 !important;
    border-radius: 50%;
    color: white !important;
}

.dashboard-date-picker .react-calendar__tile--active,
.dashboard-date-picker .react-calendar--selectRange .react-calendar__tile--hover {
    background: linear-gradient(100deg, #ff5733 0.25%, #fd4c26 46.18%, #fa8d76 97.15%);
    color: white !important;
    border-radius: 50% !important;
}

.dashboard-date-picker .react-calendar__tile--now {
    /* background: transparent; */
    border-radius: 50%;
}

.analytics-calendar-wrapper {
    position: relative;
}

.analytics-calendar-wrapper .calendar-custom-wrapper {
    /* top: 40px !important; */
}

.overview-banner,
.overview-banner .slick-list,
.overview-banner .slick-track,
.overview-banner .slick-slide > div:first-child {
    height: 100%;
}

.test .slick-track {
    margin-left: 0;
    margin-right: 0;
}

iframe [name='__uspapiLocator'] {
    display: none !important;
}

@media screen and (max-width: 599px) {
    .banner-slider {
        padding-right: 0px;
    }

    .banner-slider-img {
        border-radius: 6px;
        height: 150px;
    }
}

/* .jknkFS::after {
  border-color: #efefef !important;
  top: calc(50% - 1.5px) !important;
} */
.MuiPickersDay-today {
    border: none !important;
}

.qualWinners:nth-child(even)::before {
    border-top: none !important;
}

.qualWinners:nth-child(odd):not(:last-child)::after {
    border-right: none !important;
    border-top: none !important;
}

.qualWinners:nth-child(even)::after {
    border-bottom: none !important;
    border-right: none !important;
}

.qualWinnerBox::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 1px;
    background-color: #eee;
}

.gm-style-mtc-bbw .gm-style-mtc:first-of-type > button {
    height: 25px !important;
    font-size: 12px !important;
}

.gm-style-mtc-bbw .gm-style-mtc:last-of-type > button {
    font-size: 12px !important;
    height: 25px !important;
}

.jknkFS::after {
    border-color: #efefef !important;
    /* top: calc(50% - 12.5px) !important; */
}

/* .dWYBbN::after{
  border-color: #efefef !important;

} */
.dWYBbN::before {
    border-color: #efefef !important;
    border-top: 1px solid #efefef !important;
}

.gCXEnU::after {
    border-color: #efefef !important;
}

.gCXEnU::before {
    border-color: #efefef !important;
}

.jknkFS::before {
    border-color: #efefef !important;
    /* top: calc(50% - 1.5px) !important; */
}

.MuiPickersDay-today {
    border: none !important;
}

.MuiDialogActions-root button {
    background: transparent !important;
}

.MuiDialogActions-root button:hover {
    color: #ff5733;
}

.date-of-birth .MuiInputBase-formControl {
    height: 50px !important;
}

.MuiOutlinedInput-root {
    border: 1px solid #efeff0;
}

.MuiOutlinedInput-root fieldset {
    border-width: 0px;
}

.MuiOutlinedInput-root:hover fieldset {
    border-width: 0px;
    transform: none; /* Added the transform property */
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-width: 0px;
}

/* For .MuiOutlinedInput-input */
.MuiOutlinedInput-input {
    /* padding: 9px 14px; */
    padding-right: 0px !important; /* Added !important to override any existing styles */
}

*,
html {
    scroll-behavior: smooth;
}

.my-scroller::-webkit-scrollbar {
    display: none !important;
}

/* .my-scroller:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, transparent, white);
  pointer-events: none;
} */
:root {
    --primary-color: #ff5733; /* Fallback color */
}

.svg-current-color,
.svg-current-color path {
    fill: currentColor;
}

.svg-current-color_generic,
.svg-current-color_generic path {
    fill: var(--primary-color);
}

.svg-current-signup,
.svg-current-signup path {
    stroke: var(--primary-color);
}

.svg-current-arrows,
.svg-current-arrows rect {
    fill: var(--primary-color);
}

.test-slider img {
    max-width: 55%;
    margin: auto;
}

.custom .MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.custom .MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* [dir='ltr'] .notFirst.sc-imWYAI:nth-child(odd):not(:last-child)::before {
  content: '';
  position: absolute;
  height: 50%;
  width: 1.5em;
  left: 0;
  border-top: 1px solid #efeff0 !important;
  border-left: 1px solid #efeff0 !important;
  top: calc(50% - 0.5px);
}

[dir='ltr'] .notFirst.sc-imWYAI:nth-child(even)::before {
  border-left: 1px solid #efeff0 !important;
  border-bottom: 1px solid #efeff0 !important;
  border-top: 0;
  top: -0.5px;
  left: 0px;
  content: '';
  position: absolute;
  height: 50%;
  width: 1.5em;
} */
/* .odd > div > div > div:nth-child(2) > div > div:nth-child(2) {
  width: 94%;
  margin: 0 10px;
}
.even > div > div > div > div > div:nth-child(2) {
  width: 94%;
  margin: 0 10px;
} */
@media (max-width: 768px) {
    /* Adjust the max-width value based on your target screen size */
    .odd > div > div > div:nth-child(2) > div > div:nth-child(2) {
        width: 94%;
        margin: 0 10px;
    }

    .even > div > div > div > div > div:nth-child(2) {
        width: 94%;
        margin: 0 10px;
    }
}

.highlighted > div > div:nth-child(1) > div {
    background-color: #fae5e5;
}

/* .gCXEnU {
  padding: 0em 1.3em;
} */
.lazy-load-image-background.opacity {
    opacity: 1;
}

iframe {
    display: none;
}

.MuiTabs-scrollButtons {
    display: inherit !important;
    padding: 0px 0px 10px 0px;
    width: max-content !important;
}


@media screen and (max-width: 600px) {
    #CookiebotWidget:not(.CookiebotWidget-inactive) {
        bottom: 65px !important;
        left: 10px;
    }
}

#CookiebotWidget .CookiebotWidget-logo svg path {
    fill: #ffffff !important;
}